import React, { useEffect, useContext, useState } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { observer } from 'mobx-react-lite';

import {
  useManagedConfirmationModal,
  ConfirmationModalContext,
} from 'src/components/ConfirmationModal';
import RoomDetailsModal from 'src/screens/Dashboard/Rooms/RoomDetailsModal/RoomDetailsModal';
import Dashboard from 'src/screens/Dashboard/Dashboard';
import NotificationOverlay from 'src/components/NotificationOverlay/NotificationOverlay';
import { useStore } from 'src/context/StoreContext';
import ActionCableContext from 'src/context/ActionCableContext';
import TaskScreenMobile from 'src/screens/Dashboard/Tasks/TaskScreen/TaskScreenMobile';
import GuestMessageScreenMobile from 'src/screens/Dashboard/GuestMessages/GuestMessageScreenMobile';
import DashboardMobile from 'src/screens/Dashboard/DashboardMobile';
import useBreakpoints from 'src/utils/useBreakpoints';
import Login from './screens/Auth/Login/Login';
import Error404 from './Error404';
import ImagePreviewModal, {
  ImagePreviewModalContext,
} from 'src/components/ImagePreviewModal';

export const DASHBOARD_PATH = '/dashboard';
export const LOGIN_PATH = '/login';

const LoginGateRoutes = () => {
  return (
    <Switch>
      <Route exact path={LOGIN_PATH}>
        <Login />
      </Route>
      <Redirect to={LOGIN_PATH} />
    </Switch>
  );
};

const LoggedInRoutes = observer(() => {
  const location = useLocation();
  const background = location.state && location.state.background;
  const { isSmallScreen } = useBreakpoints();
  const {
    ConfirmationModal,
    showConfirmationModal,
  } = useManagedConfirmationModal();
  const [selectedImageURI, setSelectedImageURI] = useState(null);
  const [imagePreviewTitle, setImagePreviewTitle] = useState(null);

  const routes = (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to={DASHBOARD_PATH} />
        </Route>
        <Route exact path={LOGIN_PATH}>
          <Redirect to={DASHBOARD_PATH} />
        </Route>
        <Route path="/rooms/:id">
          <Dashboard />
        </Route>
        <Route exact path={DASHBOARD_PATH}>
          <Dashboard />
        </Route>
        <Route exact path="/tasks">
          <Redirect to={DASHBOARD_PATH} />
        </Route>
        <Route exact path="/messages">
          <Redirect to={DASHBOARD_PATH} />
        </Route>
        <Route path="*" component={Error404} />
        <NotificationOverlay />
      </Switch>
      {background ? (
        <Route path="/rooms/:id/tasks/:taskId">
          <RoomDetailsModal />
        </Route>
      ) : null}
      <ConfirmationModal />
      <ImagePreviewModal
        imageURI={selectedImageURI}
        title={imagePreviewTitle}
        onClose={() => {
          setSelectedImageURI(null);
          setImagePreviewTitle(null);
        }}
      />
    </>
  );

  const mobileRoutes = (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to={DASHBOARD_PATH} />
        </Route>
        <Route exact path={LOGIN_PATH}>
          <Redirect to={DASHBOARD_PATH} />
        </Route>
        <Route path="/rooms/:id">
          <DashboardMobile />
        </Route>
        <Route exact path={DASHBOARD_PATH}>
          <DashboardMobile />
        </Route>
        <Route exact path="/tasks">
          <TaskScreenMobile />
        </Route>
        <Route exact path="/messages">
          <GuestMessageScreenMobile />
        </Route>
        <Route path="*" component={Error404} />
        <NotificationOverlay />
      </Switch>
      {background ? (
        <Route path="/rooms/:id/tasks/:taskId">
          <RoomDetailsModal />
        </Route>
      ) : null}
      <ConfirmationModal />
      <ImagePreviewModal
        imageURI={selectedImageURI}
        title={imagePreviewTitle}
        onClose={() => {
          setSelectedImageURI(null);
        }}
      />
    </>
  );

  return (
    <ConfirmationModalContext.Provider value={{ showConfirmationModal }}>
      <ImagePreviewModalContext.Provider
        value={{
          showImagePreviewModal: ({ imageURI, title }) => {
            setSelectedImageURI(imageURI);
            setImagePreviewTitle(title);
          },
        }}
      >
        {isSmallScreen ? mobileRoutes : routes}
      </ImagePreviewModalContext.Provider>
    </ConfirmationModalContext.Provider>
  );
});

// eslint-disable-next-line react/prop-types
const Routes = observer(({ allCookies }) => {
  const {
    hotelStore,
    roomStore,
    taskStore,
    textMessageStore,
    resetStore,
  } = useStore();
  const actionCableConsumer = useContext(ActionCableContext);

  useEffect(() => {
    if (
      process.env.REACT_APP_AUTH_COOKIE_NAME in allCookies &&
      hotelStore.selectedHotelId
    ) {
      actionCableConsumer.subscriptions.create(
        {
          channel: 'TasksChannel',
          hotel_id: hotelStore.selectedHotelId,
        },
        {
          received: ({ taskId, method }) => {
            if (method === 'FETCH' && taskId) {
              taskStore.fetchTask(taskId);
            }
          },
        }
      );

      actionCableConsumer.subscriptions.create(
        {
          channel: 'RoomsChannel',
          hotel_id: hotelStore.selectedHotelId,
        },
        {
          received: ({ roomId, method }) => {
            if (method === 'FETCH' && roomId) {
              roomStore.fetchRoom(roomId);
            } else if (method === 'FETCH') {
              roomStore.fetchAllRooms(hotelStore.selectedHotelId);
            }
          },
        }
      );

      actionCableConsumer.subscriptions.create(
        {
          channel: 'TextMessagesChannel',
          hotel_id: hotelStore.selectedHotelId,
        },
        {
          received: ({ textMessageId, method }) => {
            if (method === 'FETCH' && textMessageId) {
              textMessageStore.fetchTextMessage(textMessageId);
            } else if (method === 'FETCH') {
              textMessageStore.fetchAllTextMessages(hotelStore.selectedHotelId);
            }
          },
        }
      );
    } else {
      actionCableConsumer?.subscriptions.subscriptions.forEach(
        (subscription) => {
          subscription.unsubscribe();
        }
      );
    }
  }, [
    process.env.REACT_APP_AUTH_COOKIE_NAME in allCookies,
    hotelStore.selectedHotelId,
  ]);

  if (!(process.env.REACT_APP_AUTH_COOKIE_NAME in allCookies)) {
    resetStore();
    return <LoginGateRoutes />;
  }

  return <LoggedInRoutes />;
});

const RoutesWithCookies = withCookies(Routes);

const LBRouterSwitch = () => (
  <Switch>
    <RoutesWithCookies />
  </Switch>
);

export default LBRouterSwitch;
