import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';

import { useStore } from 'src/context/StoreContext';
import { INCOMPLETE_STATUS, COMPLETE_STATUS } from 'src/models/TaskStore';
import { ReactComponent as CloseImage } from 'src/assets/icons/close.svg';
import TaskDetailsCardText from './components/TaskDetailsCardText';
import TaskDetailsCardHeader from './components/TaskDetailsCardHeader';
import { useImagePreviewModal } from 'src/components/ImagePreviewModal';

const MARK_AS_COMPLETED_TEXT = 'MARK AS COMPLETED';
const REOPEN_TASK_TEXT = 'REOPEN TASK';
const UPDATE_STATUS_BUTTON = 'UPDATE_STATUS_BUTTON';
const EDIT_TASK_BUTTON_LABEL = 'EDIT TASK';

const Container = styled('div')`
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
  padding: 16px;
  width: 95%;
  max-width: 400px;
  min-height: 70px;
`;

const CloseIconContainer = styled('div')`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

const CloseIcon = styled(CloseImage)`
  width: 12px;
  fill: #737373;
`;

const TaskImageContainer = styled('div')``;

const TaskDetailsContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const AdditionalNotesContainer = styled('div')`
  font-size: 14px;
  margin-bottom: none;
`;

const ButtonsContainer = styled('div')`
  display: flex;
  margin-top: 16px;
`;

const TaskDetailsCard = ({
  task,
  setSelectedTask,
  setEditTaskOpen,
  setTaskToEdit,
}) => {
  const {
    taskStore,
    userStore: { authenticatedUser },
  } = useStore();
  const { showImagePreviewModal } = useImagePreviewModal();

  const statusButtonText =
    task.status === COMPLETE_STATUS ? REOPEN_TASK_TEXT : MARK_AS_COMPLETED_TEXT;

  const toggleStatus = () => {
    const { status } = task;
    const newStatus =
      status === INCOMPLETE_STATUS ? COMPLETE_STATUS : INCOMPLETE_STATUS;
    const taskUpdates = {
      status: newStatus,
    };
    taskStore.patchTask({
      taskId: task.id,
      options: {
        body: { task: taskUpdates },
      },
    });
    setSelectedTask(null);
  };

  return (
    <Container>
      <TaskImageContainer />
      <TaskDetailsContent>
        <HeaderContainer>
          <TaskDetailsCardHeader task={task} />
        </HeaderContainer>
        <TextContainer>
          <TaskDetailsCardText task={task} />
          {task.translatedAditionalNotes ? (
            <AdditionalNotesContainer>
              <Typography variant="body2">
                {task.translatedAditionalNotes}
              </Typography>
            </AdditionalNotesContainer>
          ) : null}
        </TextContainer>

        {task?.image && (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              marginTop: 16,
            }}
          >
            <img
              src={task?.image}
              alt="task"
              height="200"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                showImagePreviewModal({
                  title: task.description[authenticatedUser.languagePreference],
                  imageURI: task.image,
                });
              }}
            />
          </div>
        )}

        <ButtonsContainer>
          <Button
            color="grey"
            variant="text"
            style={{
              marginRight: '10px',
            }}
            onClick={() => {
              setTaskToEdit(task);
              setEditTaskOpen(true);
            }}
          >
            {EDIT_TASK_BUTTON_LABEL}
          </Button>
          <Button
            data-testid={UPDATE_STATUS_BUTTON}
            onClick={toggleStatus}
            variant={task.status === COMPLETE_STATUS ? 'outlined' : 'contained'}
          >
            {statusButtonText}
          </Button>
        </ButtonsContainer>
      </TaskDetailsContent>
      <CloseIconContainer onClick={() => setSelectedTask(null)}>
        <CloseIcon />
      </CloseIconContainer>
    </Container>
  );
};

TaskDetailsCard.propTypes = {
  task: PropTypes.object,
  setSelectedTask: PropTypes.func,
  setEditTaskOpen: PropTypes.func,
  setTaskToEdit: PropTypes.func,
};

export default TaskDetailsCard;
