import React from 'react';
import { Button, Modal, Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import { ReactComponent as CloseImage } from 'src/assets/icons/close.svg';

export interface ImagePreviewModalProps {
  title?: string;
  imageURI?: string;
  onClose: () => void;
}

const ImagePreviewModal = ({
  title,
  imageURI,
  onClose,
}: ImagePreviewModalProps) => {
  return (
    <StyledModal open={imageURI} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          width: 550,
          outline: 0,
        }}
      >
        <div
          style={{
            paddingTop: 24,
            paddingLeft: 24,
            paddingRight: 24,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <CloseIcon onClick={onClose} />
        </div>
        {title && (
          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
              }}
            >
              {title}
            </Typography>
          </div>
        )}

        <div style={{ paddingBottom: 13 }} />
        <div
          style={{
            width: '100%',
            backgroundColor: '#ECECEC',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <img src={imageURI} height="400" />
        </div>
        <div
          style={{
            padding: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="text" color="grey" size="large" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};

export default ImagePreviewModal;

const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  outline: 0;
`;

const CloseIcon = styled(CloseImage)`
  width: 21px;
  fill: #848383;
  cursor: pointer;
`;

export const ImagePreviewModalContext = React.createContext<{
  showImagePreviewModal: ({
    imageURI,
    title,
  }: {
    imageURI: string;
    title?: string;
  }) => void;
}>({
  showImagePreviewModal: () => {},
});

export const useImagePreviewModal = () => {
  const context = React.useContext(ImagePreviewModalContext);
  if (!context) {
    throw new Error(
      'useImagePreviewModal must be used within a ImagePreviewModalProvider'
    );
  }
  return context;
};
